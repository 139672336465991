<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg bg-white rounded-lg mb-2 flex items-center p-5">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-4">
                    <label class="text-gray-400">
                        提領人 手機
                        <div class="flex">
                            <div class="phone-select">
                                <el-select v-model="filterOpts.phone_country" size="small" placeholder="请选择">
                                    <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-input v-model="filterOpts.phone" size="small" clearable> </el-input>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-span-4">
                    <label class="text-gray-400">
                        申請日期 範圍
                        <el-date-picker
                            v-model="dateInputs"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="col-span-4 flex items-end justify-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px]" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px]" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>金流編號</th>
                        <th>提領人 暱稱</th>
                        <th>金額</th>
                        <th>申請時間</th>
                        <th>查看</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                        <td>{{ item.transaction_log_id }}</td>
                        <td>{{ item.user.name }}</td>
                        <td>${{ item.amount | formatCurrency }}</td>
                        <td>{{ item.created_at | formatDateTime }}</td>
                        <td class="cursor-pointer" @click="goDetail(item.id)">
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 對帳相關 filters
import "../filterAccount";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// init search Filter options
const defaultFilterOpts = {
    transaction_log_id: "",
    phone_country: "886",
    phone: "",
    status: [-1], // 0(服務商或系統提出提領), 1(會計確認無誤), 2(匯出銀行檔案), 3(完成匯款), -1(發生錯誤)
    reason: "",
    created_date_start: "",
    created_date_end: "",
};
export default {
    name: "SubmitApplication",
    components: {
        LoadingComponent,
        MyPagination,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const phone = this.filterOpts.phone ? `${this.filterOpts.phone_country}${this.filterOpts.phone}` : "";
            return {
                ...this.filterOpts,
                phone: phone,
                limit: this.paginationData.limit,
                page: this.currentPage,
                phone_country: "886",
            };
        },
        // 手機國碼
        phoneOptions() {
            return phoneOptions;
        },
    },
    data() {
        return {
            loading: true,
            datas: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 0,
            filterOpts: { ...defaultFilterOpts },
            dateInputs: [], // 時間範圍
            choiceOpts: [],
        };
    },
    methods: {
        // 列表搜尋,分頁相關
        async getList() {
            this.loading = true;
            this.choiceOpts = [];
            const { status, data } = await this.$api
                .SearchRemittancesAPI(this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 連結相關
        goDetail(paymentID) {
            this.$router.push({
                name: "stored_detail",
                params: {
                    paymentID,
                },
                query: {
                    pre: this.$route.name,
                },
            });
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.created_date_start = "";
                this.filterOpts.created_date_end = "";
                return;
            }
            this.filterOpts.created_date_start = val[0];
            this.filterOpts.created_date_end = val[1];
        },
        "filterOpts.phone"(val) {
            if (this.$isEmpty(val)) {
                return;
            }
            if (val[0] == 0 && val[1] == 9) {
                this.filterOpts.phone = val.substring(1);
                return;
            }
        },
    },
    mounted() {
        this.getList();
    },
    activated() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.choose-number {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
    margin-right: 30px;
}

.phone-select {
    width: 150px;
    margin-right: 5px;
}

.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

.checkbox-th {
    min-width: auto;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
